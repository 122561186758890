import React, { useState, useEffect } from 'react';
import moment from 'moment';
import CustomDatePicker from './CustomDatePicker';
import calendarKeys from './calendarKeys.json';
import Heading from "shared/Heading/Heading";
import CardCategoryBox1 from "components/CardCategoryBox1/CardCategoryBox1";
import { TaxonomyType } from "data/types";
import bundleData from "data/jsons/bundles.json"
interface Props {
    showList: boolean;
    calendarTitle?: string;
}

function CalendarEvents({ showList, calendarTitle }: Props) {
    const [events, setEvents] = useState<any[]>([]);
    const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
    const calNames: Set<string> = new Set<string>(calendarKeys.bundlesList);
    const CAL_API_KEY = calendarKeys.gCalAPIKey;

    useEffect(() => {
        const fetchEvents = async () => {
            const promises = Object.values(calendarKeys.bundlesForBookings).map(async (cal, index) => {
                const url = `https://www.googleapis.com/calendar/v3/calendars/${encodeURIComponent(
                    cal.calID
                )}/events?key=${CAL_API_KEY}&maxResults=100&orderBy=startTime&singleEvents=true&timeMin=${encodeURIComponent(
                    moment().startOf('month').toISOString()
                )}&timeMax=${encodeURIComponent(moment().add(2, 'years').endOf('month').toISOString())}`;

                const response = await fetch(url);
                const { items } = await response.json();

                const events = items.map((event: any) => ({
                    id: event.id,
                    title: cal.calName,
                    start: moment(event.start.dateTime || event.start.date).toDate(),
                    end: moment(event.end.dateTime || event.end.date).toDate(),
                    timeZone: event.timeZone,
                    color: cal.calColor,
                }));
                return events;
            });

            const events = await Promise.all(promises);

            setEvents(events.flat());
        };

        fetchEvents();
    }, []);

    const filteredEvents = selectedDate ? events.reduce((titles, event) => {
        const start = moment(event.start).startOf('day');
        const end = moment(event.end).subtract(1, 'second').startOf('day');

        if (moment(selectedDate).isBetween(start, end, 'day', '[]')) {
            titles.delete(event.title);
        }

        return titles;
    }, new Set<string>(calNames)) : new Set<string>();

    const getReservedDates = (title: string | null): Date[] => {
        const reservedDates: Set<Date> = new Set();
        for (let i = 0; i < events.length; i++) {
            if (events[i].title === title) {
                const startDate: Date = new Date(events[i].start);
                let endDate: Date = new Date(events[i].end);
                // Check if event ends at 12 am of that day, and adjust the end date accordingly
                if (endDate.getHours() === 0 && endDate.getMinutes() === 0 && endDate.getSeconds() === 0) {
                    endDate.setDate(endDate.getDate() - 1);
                }
                let currentDate: Date = new Date(startDate);
                while (currentDate <= endDate) {
                    reservedDates.add(currentDate);
                    currentDate = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate() + 1);
                }
            }
        }
        return Array.from(reservedDates);
    };








    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
    };

    const bundleInfo: TaxonomyType[] = bundleData;

    const findByTitle = (title: string | null): TaxonomyType  | undefined => {
        const obj: TaxonomyType | undefined = bundleInfo.find((element) => element.name === title);
        return obj;
    };


    return (
        <div className="flex flex-col mb-8 relative" id='calendar-availability'>
            <Heading desc="Call us to book your next event!">See Our Availability</Heading>
            <div className={showList? "flex flex-col lg:flex-row" : ""}>
                <div className={showList ? "sm:basis-1 md:basis-1/2": "basis-1"}>
                 <div style={{ border: '1px solid #ccc', borderRadius: 26 }} className="relative flex flex-col  mx-auto">
                        {showList && !calendarTitle ? CustomDatePicker(handleDateChange, selectedDate, setSelectedDate) : CustomDatePicker(handleDateChange, null, setSelectedDate, getReservedDates(calendarTitle ?? null))}
                </div>
            </div>
                {showList? <div className="sm:basis-1 md:basis-1/2 mt-5 lg:mt-0 lg:ml-5">
                    <div className="grid sm:grid-cols-1 lg:grid-cols-1 xl:grid-cols-2 gap-4">
                    {[...filteredEvents].map((title) => {
                        const taxonomy = findByTitle(title);
                        if (taxonomy !== undefined) {
                            return <CardCategoryBox1 key={title} taxonomy={taxonomy} />;
                        }
                        return null;
                    })}
                    </div>
                </div> :  ''}
            </div>
        </div>
    );
}

export default CalendarEvents;
