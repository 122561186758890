import React from "react";
import HeroHeader from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import Calendar from "components/Calendar/CalendarEvents";
import SectionHero from "./SectionHero";
import PageAbout from "containers/PageAbout/PageAbout";


const BarBundles: TaxonomyType[] = [
  {
    id: "1",
    href: "/detail/sip-n-soiree-starter-pack",
    name: "Sip 'n' Soiree Starter Pack",
    taxonomy: "category",
    price: 1000,
    thumbnail:
      "images/Bundle1.png",
  },
  {
    id: "3",
    href: "/detail/mixmaster-deluxe",
    name: "MixMaster Deluxe",
    taxonomy: "category",
    price: 2500,
    thumbnail:
      "images/Bundle2.png",
  },
  {
    id: "4",
    href: "/detail/ultimate-party-palace",
    name: "The Ultimate Party Palace",
    taxonomy: "category",
    price: 2500,
    thumbnail:
      "images/Bundle3.png",
  },
  // {
  //   id: "5",
  //   href: "/detail/bundle-four",
  //   name: "Bundle 4",
  //   taxonomy: "category",
  //   price: 1600,
  //   thumbnail:
  //     "images/Bundle4.png",
  // },
  // {
  //   id: "6",
  //   href: "/detail/bundle-five",
  //   name: "Bundle 5",
  //   taxonomy: "category",
  //   price: 4500,
  //   thumbnail:
  //     "images/Bundle5.png",
  // },
  // {
  //   id: "7",
  //   href: "/detail/bundle-six",
  //   name: "Bundle 6",
  //   taxonomy: "category",
  //   price: 7999,
  //   thumbnail:
  //     "images/Bundle6.png",
  // },
  // {
  //   id: "8",
  //   href: "/detail/bundle-seven",
  //   name: "Bundle 7",
  //   taxonomy: "category",
  //   price: 4999,
  //   thumbnail:
  //     "images/Bundle7.png",
  // }
];

function PageHome() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">
        {/* SECTION HERO */}
        <HeroHeader className="pt-10 lg:pt-16 lg:pb-16" />
        <PageAbout/>
        {/* SECTION 1 */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionSliderNewCategories
            categories={BarBundles}
            categoryCardType="card4"
            itemPerRow={4}
            heading="Elevate Your Event with Our Premium Bar Rental Bundles"
            subHeading="Complete Bar Setups and Bartending Services for Any Occasion"
            sliderStyle="style2"
            uniqueClassName="PageHome_s2"
          />
        </div>

        {/* SECTION 2 */}
        <SectionHowItWork />

        <Calendar showList= {true}/>

        {/* SECTION */}
        <div className="relative py-16">
          <BackgroundSection />
          <SectionClientSay uniqueClassName="" />
        </div>

        {/* SECTION */}
          <SectionHero
            rightImg="images/CallToAction.png"
            heading="Raise the Bar"
            btnText="Contact Us"
            subHeading="Ready To Elevate Your Event Experience?"
          />
      </div>
    </div>
  );
}

export default PageHome;
