import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import Careers from "containers/JobApplication/JobApplication";
import PageContact from "containers/PageContact/PageContact";
import SiteHeader from "containers/SiteHeader";
import ListingStayDetailPage from "containers/ListingDetailPage/bundle-detail/BundleDetailPage";


export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/detail/:id", component: ListingStayDetailPage },
  { path: "/careers", component: Careers },
  { path: "/contact-us", component: PageContact },
];

const MyRoutes = () => {

  return (
   <BrowserRouter>
      <ScrollToTop />
      <SiteHeader />
        <Routes>
          {pages.map(({ component, path }) => {
            const Component = component;
            return <Route key={path} element={<Component />} path={path} />;
          })}
          <Route path="*" element={<Page404 />} />
        </Routes>
      <Footer />
    </BrowserRouter>
  );
};

export default MyRoutes;
