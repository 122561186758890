import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import React from "react";

export interface WidgetFooterMenu {
  label: string;
  href: string;
}

const widgetMenus: WidgetFooterMenu[] = [
  { label: 'Home', href: '/' },
  { label: 'Careers', href: '/careers' },
  { label: 'Contact Us', href: '/contact-us' },
];

const Footer: React.FC = () => {

  return (
    <div className="nc-Footer relative py-24 lg:py-28 border-t border-neutral-200 dark:border-neutral-700">
      <div className="container grid sm:grid-cols-1 md:grid-cols-2 gap-4">
        <div className=" "> <Logo /></div>
        <div className="">    <ul className="flex flex-col md:flex-row ">
          {widgetMenus.map((item, index) => (
            <li key={index} className="mt-5 md:mr-5 md:mt-0">
              <a
                key={index}
                className="text-neutral-6000  hover:text-black "
                href={item.href}
              >
                {item.label}
              </a>
            </li>
          ))}
        </ul></div>
        <div className=""><SocialsList1 className="flex flex-row width-full " /></div>
        <div className="text-sm  flex flex-row text-neutral-6000 ">
          Built with  <img src="https://github.githubassets.com/images/icons/emoji/unicode/2764.png" alt = "heart" style={{ 'width': 15, 'height': 15, 'marginTop': 1 }} className="mx-1" /> by
          <a
            className="hover:text-black ml-1"
            href="https://www.sonnul.com"
          >
            Sonnul.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default Footer;
