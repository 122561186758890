import React, { useState, useEffect } from 'react';
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import Checkbox from "shared/Checkbox/Checkbox";
import Select from "shared/Select/Select";
import './Accordion.css';

export function formElementBuilder(key: string, formik: any, element: any, updateInputValues: (property: string, value: string) => void, isMd: boolean, formErrors: any) {
  // const error = (formik.touched[element.name] && Boolean(formik.errors[element.name]));
  const error = formik.touched[element.name] && formik.errors[element.name] ? (<div style={{ color: 'red' }}>{formik.errors[element.name]}</div>) : null
  // const helperText = (formik.touched[element.name] && formik.errors[element.name]);

  if (element.frmBld_Component_Type === 'textFieldInput') {
    return (
      <TextFieldInputComponent
        key={`input-${key}`}
        placeholder={element.placeholder}
        label={element.label}
        size={element.size}
        name={element.name}
        type={element.type}
        value={formik.values[element.name]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={error}
      // helperText={helperText}
      // gridSpace={element.gridSpace}
      />
    );
  }
  else if (element.frmBld_Component_Type === 'textAreaInput') {
    return (
      <TextAreaInputComponent
        key={`textarea-${key}`}
        placeholder={element.placeholder}
        label={element.label}
        name={element.name}
        rows={element.rows}
        value={formik.values[element.name]}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={error}
      // helperText={helperText}
      // gridSpace={element.gridSpace}
      />
    );
  }
  else if (element.frmBld_Component_Type === 'pickerInput') {
    return (
      <PickerInputComponent
        key={`pickerInput-${key}`}
        placeholder={element.placeholder}
        label={element.label}
        name={element.name}
        value={formik.values[element.name]}
        pickerOptions={element.pickerOptions}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={error}
        formErrors={formErrors}
      // gridSpace={element.gridSpace}
      />
    );
  }
  else if (element.frmBld_Component_Type === 'checkBox') {
    return (
      <CheckBoxComponent
        key={`checkBox-${key}`}
        label={element.label}
        updateObjectProperty={updateInputValues}
        inputValues={formik.getFieldProps(element.name).value}
        name={element.name}
        pickerOptions={element.pickerOptions}
        error={error}
        formErrors={formErrors}
      // gridSpace={element.gridSpace}
      />
    );
  }
  else if (element.frmBld_Component_Type === 'sectionHeader') {
    return (
      <SectionHeaderComponent
        key={`sectionHeader-${key}`}
        header={element.header}
        subtitle={element.subtitle}
      // gridSpace={element.gridSpace}
      />
    );
  }
  else if (element.frmBld_Component_Type === 'accordionInputForm') {
    return (
      <AccordionInputForm
        key={`accordionInputForm-${key}`}
        formik={formik}
        updateObjectProperty={updateInputValues}
        formTitle={element.formTitle}
        formFields={element.formFields}
        isMd={isMd}
        formErrors={formErrors}
      />
    );
  }
}

export function elementNameForUndefined(element: any) {
  const fieldNames = [];

  if (element.frmBld_Component_Type === 'inputTable') {
    for (const objKey in element.tableRows) {
      fieldNames.push(element.tableRows[objKey].name);
    }
  } else if (element.frmBld_Component_Type === 'accordionInputForm') {
    element.formFields.forEach((ele: any) => {
      fieldNames.push(ele.name);
    });
  }

  return fieldNames;
}

export const TextFieldInputComponent = ({
  placeholder,
  label,
  size,
  name,
  type,
  value,
  onChange,
  onBlur,
  error,
  // helperText,
  // gridSpace
}: {
  placeholder: string;
  label: string;
  size: string;
  name: string;
  type: string;
  value: string;
  onChange: any;
  onBlur: any;
  error: any;
}) => {
  return (
    <label className="block">
      <Label>{label}</Label>
      <Input
        placeholder={placeholder}
        type={type}
        // size={size}
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className="mt-1"
      />
      {error}
    </label>
  );
};

export const TextAreaInputComponent = ({
  placeholder,
  label,
  name,
  rows,
  value,
  onChange,
  onBlur,
  error,
  // helperText,
  // gridSpace
}: {
  placeholder: string;
  label: string;
  name: string;
  value: string;
  rows: number;
  onChange: any;
  onBlur: any;
  error: any;
}) => {
  return (
    <label className="block">
      <Label>{label}</Label>
      <Textarea
        className="mt-1"
        placeholder={placeholder}
        name={name}
        rows={rows}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      />
      {error}
    </label>
  );
};

export const PickerInputComponent = ({
  placeholder,
  label,
  name,
  value,
  pickerOptions,
  onChange,
  onBlur,
  error,
  formErrors,
  // gridSpace
}: {
  placeholder: string;
  label: string;
  name: string;
  value: string;
  pickerOptions: string[];
  onChange: any;
  onBlur: any;
  error: any;
  formErrors: any;
}) => {
  const pickerElements: any[] = [];

  pickerOptions.forEach((element) => {
    pickerElements.push(
      { label: element, value: element }
    );
  });


  return (
    <div>
      <p>{label}</p>
      <Select
        name={name}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
      >
        <option value=""></option>

        {pickerElements.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </Select>
      {error ?
        <p style={{
          color: '#d32f2f',
          fontWeight: 400,
          fontSize: '0.75rem',
          lineHeight: 1.66,
          textAlign: 'left',
          marginTop: 3,
          marginRight: 14,
          marginBottom: 0,
          marginLeft: 14
        }}>
          {formErrors[name]}
        </p>
        : <></>}
    </div>
  );
};

export const CheckBoxComponent = ({
  label,
  updateObjectProperty,
  inputValues,
  name,
  pickerOptions,
  error,
  formErrors,
  // gridSpace
}: {
  label: string,
  updateObjectProperty: (property: string, value: string) => void,
  inputValues: string,
  name: string,
  pickerOptions: string[],
  error: any,
  formErrors: any;
}) => {
  const [checkedValues, setCheckedValues] = useState(
    inputValues === '' ? [] : inputValues.split(', ')
  );

  useEffect(() => {
    updateObjectProperty(name, checkedValues.join(', '));
  }, [checkedValues]);

  const handleChange = (checked: boolean, label: string) => {
    const checkedValue = label;
    if (checked) {
      setCheckedValues([...checkedValues, checkedValue]);
    } else {
      setCheckedValues(checkedValues.filter((value) => value !== checkedValue));
    }
  };

  const pickerElements: any[] = [];

  pickerOptions.forEach((element) => {
    pickerElements.push(
      <Checkbox
        key={`pickerOption-${element}`}
        label={element}
        name={name}
        defaultChecked={checkedValues.includes(element)}
        onChange={handleChange}
      />
    );
  });

  return (
    <div>
      <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-5">
        <label className="text-lg font-semibold" htmlFor="">
          {label}
        </label>
        {pickerElements}
      </div>
      {error ?
        <p style={{
          color: '#d32f2f',
          fontWeight: 400,
          fontSize: '0.75rem',
          lineHeight: 1.66,
          textAlign: 'left',
          marginTop: 3,
          marginRight: 14,
          marginBottom: 0,
          marginLeft: 14
        }}>
          {formErrors[name]}
        </p>
        : <></>}
    </div>
  );
};

export const SectionHeaderComponent = ({
  header,
  subtitle,
  // gridSpace
}: {
  header: string,
  subtitle: string,
}) => {
  return (
    <div>
      <h2 className="text-2xl font-semibold">{header}</h2>
      <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
        {subtitle}
      </span>
    </div>
  );
};

export const AccordionInputForm = ({
  formik,
  updateObjectProperty,
  formTitle,
  formFields,
  // formikValues,
  isMd,
  formErrors
}: {
  formik: any,
  updateObjectProperty: (property: string, value: string) => void,
  formTitle: string,
  formFields: any,
  isMd: boolean,
  formErrors: any,
}) => {
  const [activeIndex, setActiveIndex] = useState<number | null>(null);
  const handleClick = (index: number) => {
    if (index === activeIndex) {
      setActiveIndex(null);
    } else {
      setActiveIndex(index);
    }
  };

  const workInputForm: any = [];
  formFields.forEach((formElements: any) => {
    const key = `${formElements.name}`;

    workInputForm.push(
      formElementBuilder(
        key, formik, formElements, updateObjectProperty, isMd, formErrors
      )
    );
  });

  return (
    <div className='accordion'>
      <div className="accordion-item" onClick={() => handleClick(0)}>
        <h2>{formTitle}</h2>
        <div className="accordion-icon">{activeIndex === 0 ? '-' : '+'}</div>
      </div>
      {activeIndex === 0 && <div className="accordion-content" >{workInputForm}</div>}
    </div>
  );
};