import React, { useState, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Header from "components/SectionHero/SectionHero3";
import applicationFormBartender from './applicationFormBartender.json';
// import emailJSData from './emailJSKeys.json';
// import emailjs from 'emailjs-com';

import { BuildForm } from 'shared/BuildForm/BuildForm';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
    // workType: yup.string().trim().required('workType is required'),
    // firstName: yup.string().trim().min(2, 'Please enter a valid first name').max(50, 'Please enter a valid first name').required('Please specify your first name'),
    // lastName: yup.string().trim().min(2, 'Please enter a valid last name').max(50, 'Please enter a valid last name').required('Please specify your last name'),
    // email: yup.string().trim().email('Please enter a valid email address').required('Email is required'),
    // mobilePhone: yup.string().trim().required('Phone Number is required'),
});

export interface PageContactProps {
    className?: string;
}

interface FormValues {
    firstName: string,
    middleName: string,
    lastName: string,
    streetAddress: string,
    city: string,
    state: string,
    zipCode: string,
    email: string,
    mobilePhone: string,
    startWorkDate: string,
    salaryDesired: string,
    highSchoolDiploma: string,
    workType: string,
    workTime: string,
    workStatus: string,
    usAuthorized: string,
    canPerformDuties: string,
    school1Name: string,
    degree1: string,
    school1Location: string,
    schoolOtherName: string,
    degreeOther: string,
    schoolOtherLocation: string,
    specialSkills: string,
    emergencyContactFullName: string,
    emergencyContactPhoneNumber: string,
    references1Name: string,
    references1Location: string,
    references1Phone: string,
    references1Relationship: string,
    references2Name: string,
    references2Location: string,
    references2Phone: string,
    references2Relationship: string,
    workHistory1Title: string,
    workHistory1StartDate: string,
    workHistory1EndDate: string,
    workHistory1CompanyName: string,
    workHistory1Supervisor: string,
    workHistory1PhoneNumber: string,
    workHistory1City: string,
    workHistory1State: string,
    workHistory1ZipCode: string,
    workHistory1Duties: string,
    workHistory1ReasonForLeaving: string,
    workHistory1AllowedToContact: string,
    workHistory2Title: string,
    workHistory2StartDate: string,
    workHistory2EndDate: string,
    workHistory2CompanyName: string,
    workHistory2Supervisor: string,
    workHistory2PhoneNumber: string,
    workHistory2City: string,
    workHistory2State: string,
    workHistory2ZipCode: string,
    workHistory2Duties: string,
    workHistory2ReasonForLeaving: string,
    availabilitySundayDays: string,
    availabilitySundaySwings: string,
    availabilitySundayGraves: string,
    availabilityMondayDays: string,
    availabilityMondaySwings: string,
    availabilityMondayGraves: string,
    availabilityTuesdayDays: string,
    availabilityTuesdaySwings: string,
    availabilityTuesdayGraves: string,
    availabilityWednesdayDays: string,
    availabilityWednesdaySwings: string,
    availabilityWednesdayGraves: string,
    availabilityThursdayDays: string,
    availabilityThursdaySwings: string,
    availabilityThursdayGraves: string,
    availabilityFridayDays: string,
    availabilityFridaySwings: string,
    availabilityFridayGraves: string,
    availabilitySaturdayDays: string,
    availabilitySaturdaySwings: string,
    availabilitySaturdayGraves: string
};

function JobApplication() {
    // const [submitting, setSubmitting] = useState(false);
    const [activePageIndex, setActivePageIndex] = useState(0);
    const [formErrors, setFormErrors] = useState({});
    // const [formHasErrors, setFormHasErrors] = useState(false);
    const [isMd, setIsMd] = useState(false);

    useEffect(() => {
        const mediaQuery = window.matchMedia("(min-width: 960px)");
        setIsMd(mediaQuery.matches);

        const handleChange = (e: any) => {
            setIsMd(e.matches);
        };

        mediaQuery.addEventListener("change", handleChange);
        return () => mediaQuery.removeEventListener("change", handleChange);
    }, []);

    const inputValues: FormValues = {
        firstName: '',
        middleName: '',
        lastName: '',
        streetAddress: '',
        city: '',
        state: '',
        zipCode: '',
        email: '',
        mobilePhone: '',
        startWorkDate: '',
        salaryDesired: '',
        highSchoolDiploma: '',
        workType: '',
        workTime: '',
        workStatus: '',
        usAuthorized: '',
        canPerformDuties: '',
        school1Name: '',
        degree1: '',
        school1Location: '',
        schoolOtherName: '',
        degreeOther: '',
        schoolOtherLocation: '',
        specialSkills: '',
        emergencyContactFullName: '',
        emergencyContactPhoneNumber: '',
        references1Name: '',
        references1Location: '',
        references1Phone: '',
        references1Relationship: '',
        references2Name: '',
        references2Location: '',
        references2Phone: '',
        references2Relationship: '',
        workHistory1Title: '',
        workHistory1StartDate: '',
        workHistory1EndDate: '',
        workHistory1CompanyName: '',
        workHistory1Supervisor: '',
        workHistory1PhoneNumber: '',
        workHistory1City: '',
        workHistory1State: '',
        workHistory1ZipCode: '',
        workHistory1Duties: '',
        workHistory1ReasonForLeaving: '',
        workHistory1AllowedToContact: '',
        workHistory2Title: '',
        workHistory2StartDate: '',
        workHistory2EndDate: '',
        workHistory2CompanyName: '',
        workHistory2Supervisor: '',
        workHistory2PhoneNumber: '',
        workHistory2City: '',
        workHistory2State: '',
        workHistory2ZipCode: '',
        workHistory2Duties: '',
        workHistory2ReasonForLeaving: '',
        availabilitySundayDays: '',
        availabilitySundaySwings: '',
        availabilitySundayGraves: '',
        availabilityMondayDays: '',
        availabilityMondaySwings: '',
        availabilityMondayGraves: '',
        availabilityTuesdayDays: '',
        availabilityTuesdaySwings: '',
        availabilityTuesdayGraves: '',
        availabilityWednesdayDays: '',
        availabilityWednesdaySwings: '',
        availabilityWednesdayGraves: '',
        availabilityThursdayDays: '',
        availabilityThursdaySwings: '',
        availabilityThursdayGraves: '',
        availabilityFridayDays: '',
        availabilityFridaySwings: '',
        availabilityFridayGraves: '',
        availabilitySaturdayDays: '',
        availabilitySaturdaySwings: '',
        availabilitySaturdayGraves: ''
    };

    const formik = useFormik({
        initialValues: inputValues,
        validationSchema: validationSchema,
        onSubmit: values => {
            console.log("Submiting Form");

            // setSubmitting(true);
            console.log(values);

            // emailjs.send(emailJSData.serviceId, emailJSData.templateId, values, emailJSData.userId)
            //   .then((response) => {
            //     console.log('SUCCESS!', response.status, response.text);
            //   }, (error) => {
            //     console.log('FAILED...', error);
            //   });

            // setSubmitting(false);
            formik.resetForm();
        },
    });

    const theFormElements: any = BuildForm(formik, applicationFormBartender, formErrors, isMd);

    async function handleNextPage(event: any) {
        event.preventDefault()

        const fieldsToValidate = theFormElements.formPagesNames[activePageIndex];
        const frmErrors = await formik.validateForm();
        setFormErrors(frmErrors);
        const pageFields: any[] = [];

        fieldsToValidate.forEach(async (field: string) => {
            if (Object.keys(frmErrors).includes(field)) {
                pageFields.push(field);
            }
        });

        if (pageFields.length > 0) {
            const fieldError: { [key: string]: any } = {};
            pageFields.forEach(key => {
                fieldError[key] = true;
            });
            // setFormHasErrors(true);
            formik.setTouched(fieldError);
        }
        else {
            // setFormHasErrors(false);
            setActivePageIndex((pageIndex) => pageIndex + 1);
        }
    }

    function handlePrevPage() {
        setActivePageIndex((pageIndex) => pageIndex - 1);
    }

    return (
        <div className="mb-24 lg:mb-32">
            <div className="container px-1 sm:px-4 mb-24 ">
                <Header />
            </div>
            <div className="container max-w-7xl mx-auto">
                <div className="flex-shrink-0 grid grid-cols-1 ">
                    <form className="grid grid-cols-1 gap-6" onSubmit={formik.handleSubmit}>
                        {theFormElements.formPages[activePageIndex]}
                        <div style={{ display: "flex", justifyContent: "space-between" }}>
                            {activePageIndex === 0 ? (
                                <>
                                    <div style={{ flexGrow: 1 }} />
                                    <ButtonPrimary type="button" onClick={handleNextPage}>Next Page</ButtonPrimary>
                                </>
                            ) : (
                                <>
                                    <ButtonPrimary type="button" onClick={handlePrevPage}>Previous Page</ButtonPrimary>
                                    {activePageIndex === theFormElements.formPages.length - 1 ? (
                                        <ButtonPrimary type="submit">Submit</ButtonPrimary>
                                    ) : (
                                        <ButtonPrimary type="button" onClick={handleNextPage}>Next Page</ButtonPrimary>
                                    )}
                                </>
                            )}
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};

export default JobApplication;
