import React, { FC } from "react";

interface Props {
  dayOfMonth: number;
  date?: Date | undefined;
  isReserved?: Boolean | false;
}

const DatePickerCustomDay: FC<Props> = ({ dayOfMonth, date, isReserved }) => {
  const className = isReserved ? 'react-datepicker__day--disabled' : '';

  return <span className={`react-datepicker__day_span ${className}`}>{dayOfMonth}</span>;

};
export default DatePickerCustomDay;
