import * as myInputs from './Inputs';

export function BuildForm(
  formik: any,
  formData: any,
  formErrors: any,
  isMd: boolean
) {
  const pages = [];
  const pagesWithName = [];

  for (const [pagesKey, pagesValues] of Object.entries(formData.pages)) {
    const curPage: any = pagesValues;
    const formElements = [];
    const formElementsName = [];

    for (let j = 0; j < curPage.length; j++) {
      const element = curPage[j];
      const key = `${pagesKey}-${j}`;

      if (element.name === undefined) {
        const eleNames = myInputs.elementNameForUndefined(element);
        formElementsName.push(...eleNames);
      } else {
        formElementsName.push(element.name);
      }

      formElements.push(
        myInputs.formElementBuilder(
          key, 
          formik, 
          element, 
          (property: string, value: string) => formik.setFieldValue(property, value), 
          isMd, 
          formErrors,
        )
      );
    }

    pagesWithName.push(formElementsName);
    pages.push(formElements);
  }

  return { 
    formPages: pages,
    formPagesNames: pagesWithName
  };
}