import React, { useState, useEffect, FC } from "react";
import { Helmet } from "react-helmet";
import ButtonPrimary from "shared/Button/ButtonPrimary";

import contactForm from './contactForm.json';
// import emailJSData from './emailJSKeys.json';
// import emailjs from 'emailjs-com';

import { BuildForm } from 'shared/BuildForm/BuildForm';
import { useFormik } from 'formik';
import * as yup from 'yup';

const validationSchema = yup.object({
  firstName: yup.string().trim().min(2, 'Please enter a valid first name').max(50, 'Please enter a valid first name').required('Please specify your first name'),
  lastName: yup.string().trim().min(2, 'Please enter a valid last name').max(50, 'Please enter a valid last name').required('Please specify your last name'),
  email: yup.string().trim().email('Please enter a valid email address').required('Email is required'),
  mobilePhone: yup.string().trim().required('Phone Number is required'),
});

export interface PageContactProps {
  className?: string;
}

interface FormValues {
  firstName: string,
  lastName: string,
  email: string,
  mobilePhone: string,
  message: string,
};

const info = [
  {
    title: "☎ PHONE",
    desc: "(530) 755 - 7100",
  },
  {
    title: "💌 EMAIL",
    desc: "party_2_u@yahoo.com",
  },
    {
    title: "📍 LOCATION",
    desc: "1441 Garden Highway Yuba City, CA 95991",
  },
];

const PageContact: FC<PageContactProps> = ({ className = "" }) => {
  // const [submitting, setSubmitting] = useState(false);
  const [activePageIndex, setActivePageIndex] = useState(0);
  const [formErrors, setFormErrors] = useState({});
  // const [formHasErrors, setFormHasErrors] = useState(false);
  const [isMd, setIsMd] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(min-width: 960px)");
    setIsMd(mediaQuery.matches);
    setActivePageIndex(0);
    setFormErrors({});
    const handleChange = (e: any) => {
      setIsMd(e.matches);
    };

    mediaQuery.addEventListener("change", handleChange);
    return () => mediaQuery.removeEventListener("change", handleChange);
  }, []);

  const inputValues: FormValues = {
    firstName: '',
    lastName: '',
    email: '',
    mobilePhone: '',
    message: ''
  };

  const formik = useFormik({
    initialValues: inputValues,
    validationSchema: validationSchema,
    onSubmit: values => {
      // setSubmitting(true);
      console.log(values);


      // emailjs.send(emailJSData.serviceId, emailJSData.templateId, values, emailJSData.userId)
      //   .then((response) => {
      //     console.log('SUCCESS!', response.status, response.text);
      //   }, (error) => {
      //     console.log('FAILED...', error);
      //   });

      // setSubmitting(false);
      formik.resetForm();
    },
  });

  const theFormElements: any = BuildForm(formik, contactForm, formErrors, isMd);


  return (
    <div
      className={`nc-PageContact overflow-hidden ${className}`}
      data-nc-id="PageContact"
    >
      <Helmet>
        <title>Contact || Party 2 U</title>
      </Helmet>
      <div className="mb-24 lg:mb-32">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Contact
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
            </div>
            <div>
              <form className="grid grid-cols-1 gap-6" onSubmit={formik.handleSubmit}>
                {theFormElements.formPages[activePageIndex]}
                <div>
                  <ButtonPrimary type="submit">Send Message</ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageContact;
