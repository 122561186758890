import moment from 'moment';
import DatePicker from "react-datepicker";
import DatePickerCustomDay from "components/DatePickerCustomDay";
import ButtonSecondary from 'shared/Button/ButtonSecondary';

function CustomDatePicker(handleDateChange: any, selectedDate: Date | null, setSelectedDate: any, reservedDates?:Date[]) {
    const today = new Date();
    const yearOptions = Array.from({ length: 3 }, (_, i) => today.getFullYear() + i);
    const months = moment.months();

    const isDayReserved = (date: Date): boolean => {
        return reservedDates !== undefined
            ? reservedDates.some((reservedDate) =>
                moment(reservedDate).isSame(moment(date), 'day')
            )
            : false;
    };


    console.log("reservedDates " + reservedDates);





    return (
        <DatePicker
            onChange={(date) => handleDateChange(date)}
            selected={ selectedDate}
            monthsShown={1}
            showPopperArrow={false}
            inline
            renderDayContents={(day, date) => (
                <DatePickerCustomDay
                    dayOfMonth={day}
                    date={date}
                    isReserved={date && isDayReserved(date)}
                />
            )}
            renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
            }) => (
                <div className='m-2'>
                    {selectedDate?.toDateString()}
                    <div style={{ display: "flex", justifyContent: "space-evenly", margin: 10}}>
                        <ButtonSecondary type="button" onClick={decreaseMonth} disabled={prevMonthButtonDisabled} className="mr-2">{"<"}</ButtonSecondary>
                        <select
                            className="calendar-select mr-2"
                            value={months[date.getMonth()]}
                            onChange={({ target: { value } }) => changeMonth(months.indexOf(value))}
                        >
                            {months.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>

                        <select
                            className="calendar-select mr-2"
                            value={date.getFullYear()}
                            onChange={({ target: { value } }) => changeYear(parseInt(value))}
                        >
                            {yearOptions.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>

                        <ButtonSecondary type="button" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>{">"}</ButtonSecondary>
                    </div>
                </div>
            )}
        />
    );
}

export default CustomDatePicker;