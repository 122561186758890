import React, { FC } from "react";

export interface SectionHero3Props {
  className?: string;
}

const SectionHero3: FC<SectionHero3Props> = ({ className = "" }) => {
  return (
<div
  className={`nc-SectionHero3 relative ${className}`}
  data-nc-id="SectionHero3"
>
  <div className="absolute z-10 inset-0 flex flex-col items-center justify-center text-center max-w-2xl mx-auto space-y-4 lg:space-y-5 xl:space-y-8">
    <span className="sm:text-lg md:text-xl font-semibold text-neutral-900">
      Flexible Schedules and Competitive Pay in Bartending Services
    </span>
    <h2 className="font-bold text-black text-3xl sm:text-4xl md:text-5xl lg:text-6xl xl:text-7xl !leading-[115%] ">
      Join Our Team
    </h2>
  </div>
  <div className="relative aspect-w-1 aspect-h-1 sm:aspect-w-4 sm:aspect-h-3 lg:aspect-w-16 lg:aspect-h-9 xl:aspect-h-8">
    <img
      className="absolute inset-0 object-cover rounded-xl opacity-80"
      src="images/CareerHeader.png"
      alt="career header"
    />
  </div>
</div>

  );
};

export default SectionHero3;
