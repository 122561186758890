import React from "react";
import { Helmet } from "react-helmet";
import MainNav1 from "components/Header/MainNav1";

const SiteHeader = () => {

  return (
    <>
      <Helmet>
        <title>Party 2 U</title>
      </Helmet>
      <div
        className="c-Header sticky top-0 w-full left-0 right-0 z-40 nc-header-bg shadow-sm dark:border-b dark:border-neutral-700"
      >
        <MainNav1/>
    </div>
    </>
  );
};

export default SiteHeader;
