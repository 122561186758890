import React, { FC, useState, useEffect } from "react";

import Calendar from "components/Calendar/CalendarEvents";
import { useLocation, useNavigate } from "react-router-dom";
import { Squares2X2Icon } from "@heroicons/react/24/outline";
import DetailPagetLayout from "../Layout";
import { useParams } from 'react-router-dom';
import jsonData from 'data/jsons/bundles.json';
import { TaxonomyType } from "data/types";



const StayDetailPageContainer: FC<{}> = () => {
  const [data, setData] = useState<TaxonomyType>();
  const { id } = useParams<{ id: string }>();
  const bundleInfo: TaxonomyType[] = jsonData;

  console.log("data !!! " + data);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const item = bundleInfo.find(
          (item: TaxonomyType) => item.href === id
        );
        setData(item);
        console.log(JSON.stringify(item));
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [id]);


  const thisPathname = useLocation().pathname;
  const router = useNavigate();


  const handleOpenModalImageGallery = () => {
    router(`${thisPathname}/?modal=PHOTO_TOUR_SCROLLABLE`);
  };

  const renderInfo = () => {
    return (
      <div className="listingSection__wrap !space-y-6">

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {data?.name}
        </h2>

        {/* 2 */}
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          <span>
            {data?.description}
          </span>
        </div>
      </div>
    );
  };

  const renderPrices = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Bar Rates </h2>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
            <div className="p-4 bg-neutral-100 dark:bg-neutral-800 flex justify-between items-center space-x-4 rounded-lg">
              <span>Monday - Thursday</span>
              <span>Call for pricing</span>
            </div>
            <div className="p-4  flex justify-between items-center space-x-4 rounded-lg">
              <span>Friday - Sunday</span>
              <span>Call for pricing</span>
            </div>
            <div className="p-4  bg-neutral-100 flex justify-between items-center space-x-4 rounded-lg">
              <span>Add-on Bartendars Service</span>
              <span>-20.00 %</span>
            </div>
            <div className="p-4  flex justify-between items-center space-x-4 rounded-lg">
              <span>Minimum number of nights</span>
              <span>1 night</span>
            </div>
            <div className="p-4 bg-neutral-100  flex justify-between items-center space-x-4 rounded-lg">
              <span>Max number of days</span>
              <span>10 nights</span>
            </div>
            <div className="p-4 flex justify-between items-center space-x-4 rounded-lg">
              <span>Additional delivery fee for locations outside of 50-mile radius.</span>
              <span>Call for pricing</span>
            </div>
          </div>
        </div>
      </div>
    );
  };



  const renderThingsToKnow = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Things to know</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Cancellation policy</h4>
          <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
            Refund 50% of the booking value when customers cancel the booking
            within 48 hours after successful booking and 14 days before the event.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Delivery & Set-up time</h4>
          <div className="mt-3 text-neutral-500 dark:text-neutral-400 max-w-md text-sm sm:text-base">
            <div className="flex space-x-10 justify-between p-3 bg-neutral-100 dark:bg-neutral-800 rounded-lg">
              <span>Set-up Time</span>
              <span>12:00 pm - 02:00 pm</span>
            </div>
            <div className="flex space-x-10 justify-between p-3">
              <span>Pick-up Time</span>
              <span>12:00 am - 04:00 pm</span>
            </div>
          </div>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <h4 className="text-lg font-semibold">Special Note</h4>
          <div className="prose sm:prose">
            <ul className="mt-3 text-neutral-500 dark:text-neutral-400 space-y-2">
              <li>
                Please avoid placing excessive weight or pressure on the bar stand, as this can damage the structure and stability of the stand.
              </li>
              <li>We kindly ask that you refrain from moving the bar stand without assistance, as it may be heavy and difficult to maneuver.</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="nc-BundleDetailPage">
      {/*  HEADER */}
      <header className="rounded-md sm:rounded-xl">
        <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
          <div
            className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer "
            onClick={handleOpenModalImageGallery}
          >
            <img
              className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
              src={data?.images?.[0]}
              alt="main images"
              sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
            />
            <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
          </div>
          {data?.images?.filter((_, i) => i >= 1 && i < 5).map((item, index) => (
            <div
              key={index}
              className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                index >= 3 ? "hidden sm:block" : ""
              }`}
            >
              <div className="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5">
                <img
                  className="absolute inset-0 object-cover rounded-md sm:rounded-xl w-full h-full"
                  src={item || ""}
                  alt=""
                  sizes="400px"
                />
              </div>

              {/* OVERLAY */}
              <div
                className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                onClick={handleOpenModalImageGallery}
              />
            </div>
          ))}

          <button
            className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 hover:bg-neutral-200 z-10"
            onClick={handleOpenModalImageGallery}
          >
            <Squares2X2Icon className="w-5 h-5" />
            <span className="ml-2 text-neutral-800 text-sm font-medium">
              Show all photos
            </span>
          </button>
        </div>
      </header>

      {/* MAIN */}
      <main className=" relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {renderInfo()}
          <div className="block lg:hidden">
          <Calendar showList={false} calendarTitle={data?.name} />
          </div>
          {renderPrices()}
          {renderThingsToKnow()}
        </div>

        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28"> <Calendar showList={false} calendarTitle={data?.name}/></div>
        </div>
      </main>
    </div>
  );
};

export default function BundleDetailPage() {
  return (
    <DetailPagetLayout>
      <StayDetailPageContainer />
    </DetailPagetLayout>
  );
}
