import React, { ReactNode} from "react";
import { useLocation, useNavigate, useSearchParams, useParams } from "react-router-dom";
import ListingImageGallery from "components/ListingImageGallery/ListingImageGallery";
import jsonData from 'data/jsons/bundles.json';
import { TaxonomyType } from "data/types";
import type { ListingGalleryImage } from "components/ListingImageGallery/utils/types";


const DetailPagetLayout = ({ children }: { children: ReactNode }) => {
  const navigate = useNavigate();
  const thisPathname = useLocation().pathname;
  const { id } = useParams<{ id: string }>();
  const [searchParams] = useSearchParams();
  const modal =  searchParams?.get("modal");
  const bundleInfo: TaxonomyType[] = jsonData;



const bundleImgs= bundleInfo.find((item: TaxonomyType) => item.href === id)?.images;
const imageGallery: ListingGalleryImage[] = (bundleImgs ?? []).map(
  (item, index): ListingGalleryImage => {
    return {
      id: index,
      url: item,
    };
  }
);



const handleCloseModalImageGallery = () => {
  let params = new URLSearchParams(document.location.search);
  params.delete("modal");
  navigate(thisPathname.replace(/\/+$/, ""));
};


  return (
    <div className="ListingDetailPage">
      <ListingImageGallery
        isShowModal={modal === "PHOTO_TOUR_SCROLLABLE"}
        onClose={handleCloseModalImageGallery}
        images={imageGallery}
      />

      <div className="container ListingDetailPage__content pb-10">{children}</div>
    </div>
  );
};

export default DetailPagetLayout;
