// import rightImg from "images/Fair.jpg";
import React, { FC } from "react";
// import SectionFounder from "./SectionFounder";
// import SectionStatistic from "./SectionStatistic";
// import { Helmet } from "react-helmet";
// import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
// import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
// import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
// import SectionClientSay from "components/SectionClientSay/SectionClientSay";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {
  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg="./images/Fair.jpg"
          heading="👋 Come Say Hi to Us!"
          btnText=""
          subHeading="Visit our booth at the fair and discover all the services we offer. We're excited to meet you and introduce you to everything we have in store. Don't miss out on this opportunity to get to know us better!"
        />
      </div>
    </div>
  );
};

export default PageAbout;
